import { SagaMiddleware } from "redux-saga";
import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import {
  getCasesApi,
  executeCaseCommandsApi,
  getCategoriesApi,
  getManagersApi,
  executeCreateCaseCommandApi,
  executeAttachableCaseCommandApi,
} from "../api/TaskCentre-API";
import {
  FETCH_TASKCENTRE_CASE_LIST,
  actionCentreCaseListPending,
  actionCentreCaseListError,
  IFetchTaskCentreCaseList,
  mergeTaskCentreCaseLists,
  IExecuteCaseCommands,
  EXECUTE_CASE_COMMANDS,
  updateTaskCentreCategories,
  FETCH_TASKCENTRE_CATEGORIES,
  updateTaskCentreManagers,
  FETCH_TASKCENTRE_MANAGERS,
  TASKCENTRE_CREATE_NEW_CASE,
  ICreateNewTaskCentreCase,
  setSelectedTaskCentreCase,
  executeCaseCommand,
  fetchTaskCentreCaseList,
  removeTaskCaseItem,
  setTaskCentreCaseCount,
} from "../actions/TaskCentre-CaseList-Actions";
import {
  ICaseDetails,
  ICaseCategory,
  CaseEventType,
  IGetCasesModel,
} from "../types/TaskCentreCases";
import { Logging, SeverityLevel } from "../../utils/logging";
import { sendInternalAppNotification } from "../actions/AppNotification-Actions";
import {
  IAppNotification,
  NotificationSource,
  NotificationType,
} from "../types/AppNotification";
import i18n from "../../localizations/i18n";
import {
  CreateCaseCommand,
  IAttachableCaseCommand,
  ICaseCommand,
} from "../types/TaskCentreCommands";
import {
  ActiveManagersClientIdMapper,
  IActiveManagersByClientId,
} from "../graphs/activeManagersByClientId";
import { IGraphQueryResponse } from "../graphs/types";
import {
  fetchVisitTasksCounts,
  findAndReplaceVisit,
} from "../actions/VisitDetails-Actions";
import {
  SignalRNotificationAction,
  SIGNALR_NOTIFICATION,
} from "../actions/SignalRNotifications-Actions";
import { SignalRNotificationTypes } from "../types/SignalRNotifications";
import {
  TaskCentreCasesQuery,
  SelectedCaseId,
} from "../selectors/TaskCentre-Selectors";
import { IVisitDetailsVisits } from "../types/VisitDetails";
import { VisitDetails } from "../selectors/VisitDetails-Selectors";
import { IGetCasesQuery } from "../types/TaskCentreCaseQuery";

type TaskCentreCaseListAction =
  | IFetchTaskCentreCaseList
  | IExecuteCaseCommands
  | ICreateNewTaskCentreCase
  | undefined;

export function registerTaskCentreCaseListSagas(
  sagaMiddleware: SagaMiddleware
) {
  sagaMiddleware.run(function* () {
    while (true) {
      const action: TaskCentreCaseListAction = yield take([
        FETCH_TASKCENTRE_CASE_LIST,
        TASKCENTRE_CREATE_NEW_CASE,
      ]);
      if (!action) return;
      switch (action.type) {
        case FETCH_TASKCENTRE_CASE_LIST:
          yield fetchCaseList(action);
          break;
        case TASKCENTRE_CREATE_NEW_CASE:
          yield createNewCase(action);
          break;
      }
    }
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_TASKCENTRE_CATEGORIES, fetchCategories);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(EXECUTE_CASE_COMMANDS, executeCommands);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_TASKCENTRE_MANAGERS, fetchManagers);
  });

  sagaMiddleware.run(function* () {
    yield takeEvery(SIGNALR_NOTIFICATION, signalRNotification);
  });
}

function* fetchCaseList(action: IFetchTaskCentreCaseList): unknown {
  yield put(actionCentreCaseListPending(action.query, action.silent));

  try {
    const clientId = yield call(AuthServiceInstance.getClientId);
    const userId = yield call(AuthServiceInstance.getUserId);

    action.query.userId = userId;

    const caseResponse: IGetCasesModel = (yield call(
      getCasesApi,
      clientId,
      action.query
    )).data;

    const selectedCaseId: number | undefined = yield select(SelectedCaseId);

    yield put(setTaskCentreCaseCount(caseResponse.totalCount));

    yield put(mergeTaskCentreCaseLists(caseResponse.cases, selectedCaseId));

    const visitDetails: IVisitDetailsVisits | undefined = yield select(
      VisitDetails
    );

    const selectedVisit = visitDetails?.visits.find(
      (x) => x.id === visitDetails?.selectedVisitId
    );

    if (
      selectedVisit &&
      !selectedVisit.hasVoucher &&
      caseResponse.cases.some(
        (x) =>
          x.vId === selectedVisit.id &&
          x.events.some((y) => y.type === CaseEventType.VoucherSent)
      )
    ) {
      yield put(findAndReplaceVisit(selectedVisit));

      if (
        selectedCaseId &&
        caseResponse.cases.find((x) => x.id === selectedCaseId)
      ) {
        yield put(removeTaskCaseItem(selectedCaseId));
      }
    }
  } catch (e) {
    Logging.captureError(
      "Saga:fetchTaskCentreCaseList",
      e,
      SeverityLevel.Error
    );
    yield put(
      actionCentreCaseListError("Failed to fetch action centre case list")
    );
  }
}

function* signalRNotification(action: SignalRNotificationAction): unknown {
  const notification = action.notification;

  if (notification.notificationType !== SignalRNotificationTypes.CaseSaved) {
    return;
  }

  const previousQuery: IGetCasesQuery | undefined = yield select(
    TaskCentreCasesQuery
  );

  const visitDetails: IVisitDetailsVisits | undefined = yield select(
    VisitDetails
  );

  if (
    previousQuery &&
    (!previousQuery.visitId || previousQuery.visitId === notification.visitId)
  ) {
    yield put(fetchTaskCentreCaseList(previousQuery, true));
  }

  if (visitDetails?.visits.some((x) => x.id === notification.visitId)) {
    yield put(fetchVisitTasksCounts([notification.visitId]));
  }
}

function* executeCommands(action: IExecuteCaseCommands): unknown {
  try {
    const busyNotification: IAppNotification = {
      Identifier: `${action.caseId}|${action.commands[0].commandType}`,
      Source: NotificationSource.TaskCentre_CaseUpdate,
      Type: NotificationType.Busy,
      ShowInNotificationCentre: false,
      NotificationTimeout: 60 * 1000,
    };

    yield put(sendInternalAppNotification(busyNotification, true));

    const clientId: number = yield call(AuthServiceInstance.getClientId);
    const authorId: number = yield call(AuthServiceInstance.getUserId);

    action.commands.forEach((c) => (c.authorId = authorId));

    const attachableCommands: IAttachableCaseCommand[] = action.commands.filter(
      (x): x is IAttachableCaseCommand => {
        const attachments = (x as IAttachableCaseCommand).attachments;

        return attachments !== undefined && attachments.length > 0;
      }
    );
    const standardCommands: ICaseCommand[] = action.commands.filter((x) => {
      const attachments = (x as IAttachableCaseCommand).attachments;

      return attachments === undefined || attachments.length === 0;
    });

    let updatedCaseResponse: ICaseDetails | undefined;

    if (standardCommands.length > 0) {
      updatedCaseResponse = (yield call(
        executeCaseCommandsApi,
        clientId,
        action.caseId,
        standardCommands
      )).data;
    }

    for (const attachableCommand of attachableCommands) {
      updatedCaseResponse = (yield call(
        executeAttachableCaseCommandApi,
        clientId,
        action.caseId,
        attachableCommand
      )).data;
    }

    if (updatedCaseResponse && updatedCaseResponse.id === action.caseId) {
      const selectedCaseId: number | undefined = yield select(SelectedCaseId);

      yield put(
        mergeTaskCentreCaseLists([updatedCaseResponse], selectedCaseId)
      );

      const completeNotification: IAppNotification = {
        Identifier: `${action.caseId}|${action.commands[0].commandType}`,
        Source: NotificationSource.TaskCentre_CaseUpdate,
        Type: NotificationType.Success,
        Message: i18n.translate("TASK_CENTRE_NOTIFICATION_SUCCESS"),
        ShowInNotificationCentre: false,
        NotificationTimeout: 5000,
      };

      yield put(sendInternalAppNotification(completeNotification, true));

      if (action.callback) {
        yield call(action.callback);
      }
    }
  } catch (e) {
    Logging.captureError("Saga:executeCommand", e, SeverityLevel.Error);

    const errorNotification: IAppNotification = {
      Identifier: `${action.caseId}|${action.commands[0].commandType}`,
      Source: NotificationSource.TaskCentre_CaseUpdate,
      Type: NotificationType.Error,
      Message: i18n.translate("TASK_CENTRE_NOTIFICATION_ERROR"),
      ShowInNotificationCentre: false,
      NotificationTimeout: 0,
    };

    yield put(sendInternalAppNotification(errorNotification, true));
  }
}

function* fetchCategories(): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);
    const categoryResponse: ICaseCategory[] = (yield call(
      getCategoriesApi,
      clientId
    )).data.sort((a: ICaseCategory, b: ICaseCategory) =>
      a.name.localeCompare(b.name)
    );

    yield put(updateTaskCentreCategories(categoryResponse));
  } catch (e) {
    Logging.captureError("Saga:fetchCategories", e, SeverityLevel.Error);
    yield put(
      actionCentreCaseListError("Failed to fetch action centre categories")
    );
  }
}

function* fetchManagers(): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);
    const managerResponse: IGraphQueryResponse<IActiveManagersByClientId> =
      (yield call(getManagersApi, clientId)).data;
    throwIfResponseContainsErrors(managerResponse);

    const managers = ActiveManagersClientIdMapper.map(managerResponse.data);

    yield put(updateTaskCentreManagers(managers));
  } catch (e) {
    Logging.captureError("Saga:fetchManagers", e, SeverityLevel.Error);
    yield put(
      actionCentreCaseListError("Failed to fetch action centre managers")
    );
  }
}

function* createNewCase(action: ICreateNewTaskCentreCase): unknown {
  try {
    yield put(actionCentreCaseListPending(undefined));

    const clientId: number = yield call(AuthServiceInstance.getClientId);
    const authorId: number = yield call(AuthServiceInstance.getUserId);

    const createCaseCommand = new CreateCaseCommand(
      action.visitId,
      action.title,
      action.status
    );
    createCaseCommand.creatorId = authorId;

    const createdCaseResponse: ICaseDetails = (yield call(
      executeCreateCaseCommandApi,
      clientId,
      createCaseCommand
    )).data;

    if (createdCaseResponse && createdCaseResponse.vId === action.visitId) {
      const selectedCaseId: number | undefined = yield select(SelectedCaseId);

      yield put(
        mergeTaskCentreCaseLists([createdCaseResponse], selectedCaseId)
      );
      yield put(setSelectedTaskCentreCase(createdCaseResponse.id));
      yield put(fetchVisitTasksCounts([action.visitId]));

      if (action.commands) {
        yield put(
          executeCaseCommand(
            createdCaseResponse.id,
            action.commands,
            action.callback
          )
        );
      } else if (action.callback) {
        yield call(action.callback);
      }
    }
  } catch (e) {
    Logging.captureError("Saga:createNewCase", e, SeverityLevel.Error);
  }
}

const throwIfResponseContainsErrors = (
  response: IGraphQueryResponse<unknown>
): void => {
  if (response.errors && response.errors.length > 0) {
    throw Error(JSON.stringify(response.errors));
  }
};
