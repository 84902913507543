import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
  availableSegments: string[];
  selectedSegments: string[];
  applySelectionCallback: (selections: string[]) => void;
}

const SegmentPicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState([] as string[]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      (selectedSegments && selectedSegments.length === 0) ||
      selectedSegments.length === props.availableSegments.length
    ) {
      selectionText = i18n.translate("SEGMENT_PICKER__ALL_SELECTED");
    } else if (selectedSegments.length === 1) {
      selectionText = selectedSegments[0];
    } else {
      selectionText = `${selectedSegments.length} ${i18n.translate(
        "SEGMENT_PICKER_SELECTED"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    return selectedSegments.join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    props.applySelectionCallback(selectedOptions);
    setSelectedSegments(selectedOptions);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (props.selectedSegments) {
      setSelectedSegments(props.selectedSegments);
    }
  }, [setSelectedSegments, props.selectedSegments]);

  return (
    <FilterWrapper
      label={i18n.translate("SEGMENT_PICKER_LABEL")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "LOCATION_SEGMENT_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={props.availableSegments}
        selectedOptions={selectedSegments}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
      />
    </FilterWrapper>
  );
};

export default SegmentPicker;
