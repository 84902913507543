import React, { createRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentFilterSetToDefault } from "../../state/actions/FilterSets-Actions";
import {
  setReportViewerBusyState,
  setReportViewerDataExportState,
  setReportViewerPageSettings,
} from "../../state/actions/ReportViewer-Actions";
import { RootState } from "../../state/reducers/RootReducer";
import ReportRender, {
  IReportRenderFunctions,
} from "./components/reportRender";
import {
  GetCurrentFilterSet,
  GetFilters,
  GetUserCurrentClient,
  GetUserCurrentBrands,
  ReportAccessTokenByReportAndDataset,
  ReportConfigurationByRef,
  GetLayout,
  GetCurrentPage,
  GetReportExportableVisualNames,
  GetReportExportableVisualTypes,
  GetDataExportIsOpen,
  AvailableLocationIdsByBrand,
} from "./state/selectors";

interface IProps {
  pageRef: string;
  containerWidth: number;
}

export interface IReportViewerFunctions {
  LaunchDataExport: () => void;
}

const ReportViewer = (props: IProps) => {
  const dispatch = useDispatch();

  const reportRenderRef = createRef<IReportRenderFunctions>();

  const reportConfiguration = useSelector((state: RootState) =>
    ReportConfigurationByRef(state, props.pageRef)
  );

  const reportAccessToken = useSelector((state: RootState) =>
    ReportAccessTokenByReportAndDataset(
      state,
      reportConfiguration?.id,
      reportConfiguration?.datasetId
    )
  );

  const exportableVisualTypes = useSelector((state: RootState) =>
    GetReportExportableVisualTypes(state, props.pageRef)
  );
  const exportableVisualNames = useSelector((state: RootState) =>
    GetReportExportableVisualNames(state, props.pageRef)
  );

  const dataExportIsOpen = useSelector(GetDataExportIsOpen);
  const currentClientId = useSelector(GetUserCurrentClient);
  const currentBrands = useSelector(GetUserCurrentBrands);
  const availableLocations = useSelector((state: RootState) =>
    AvailableLocationIdsByBrand(state, currentBrands)
  );

  const currentFilterSet = useSelector((state: RootState) =>
    GetCurrentFilterSet(state)
  );

  const filters = useSelector((state: RootState) =>
    GetFilters(state, props.pageRef)
  );

  const layout = useSelector((state: RootState) =>
    GetLayout(state, props.pageRef, props.containerWidth.toString())
  );

  const currentPage = useSelector(GetCurrentPage);

  const onReportLoaded = () => {
    dispatch(setReportViewerBusyState(false));
  };

  const onPagesLoaded = (pages: string[], defaultPage: string) => {
    dispatch(setReportViewerPageSettings(pages, defaultPage));
  };

  const onExportClose = () => {
    dispatch(setReportViewerDataExportState(false));
  };

  useEffect(() => {
    if (!currentFilterSet) {
      dispatch(setCurrentFilterSetToDefault(props.pageRef));
    }
  }, [currentFilterSet, dispatch, props.pageRef]);

  useEffect(() => {
    dispatch(setReportViewerPageSettings([], ""));
    dispatch(setReportViewerBusyState(true));
  }, [dispatch, props.pageRef]);

  useEffect(() => {
    if (reportRenderRef.current && currentPage !== "") {
      reportRenderRef.current.ChangeReportPage(currentPage);
    }
  }, [currentPage, reportRenderRef]);

  return (
    <>
      {reportConfiguration && currentFilterSet && layout && (
        <ReportRender
          ref={reportRenderRef}
          pageInternalRef={props.pageRef}
          reportId={reportConfiguration.id}
          reportUrl={reportConfiguration.reportUrl}
          accessToken={reportAccessToken}
          clientId={currentClientId}
          selectedBrands={currentBrands}
          availableLocationIds={availableLocations}
          applyDatesAsPageFilters={reportConfiguration.applyDatesAsPageFilters}
          filters={filters}
          filterSet={currentFilterSet}
          width={layout.width}
          exportableVisualTypes={exportableVisualTypes}
          exportableVisualNames={exportableVisualNames}
          exportIsOpen={dataExportIsOpen}
          onReportLoaded={onReportLoaded}
          onReportPagesLoaded={onPagesLoaded}
          onExportClose={onExportClose}
        />
      )}
    </>
  );
};

export default ReportViewer;
