import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaskCentreCategories } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { CategoryCount } from "../state/selectors";
import { store } from "../../../state/redux-store";
import { HubFilterType } from "../../../state/types/FilterSets";

interface ITaskCategoryLoaderProps {
  conditionalLoading?: boolean;
}

const TaskCategoriesLoader = ({
  conditionalLoading,
}: ITaskCategoryLoaderProps) => {
  const dispatch = useDispatch();
  const categoryCount = useSelector(CategoryCount);
  const filterSet = store.getState().FilterSetsReducer.currentFilters;
  const loadTaskCategories = filterSet.some(
    (f) =>
      f.type === HubFilterType.TaskCentreCategory &&
      (f.userSetVisibility === 1 || f.alwaysVisible === true)
  );

  useEffect(() => {
    if (!conditionalLoading || (categoryCount === 0 && loadTaskCategories)) {
      dispatch(fetchTaskCentreCategories());
    }
  }, [dispatch, categoryCount, loadTaskCategories, filterSet]);

  return <></>;
};

export default TaskCategoriesLoader;
