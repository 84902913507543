import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    backgroundColor: hgemColours.OffWhite,
    margin: "0% 0 0.5% 0.5%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    gap: "1%",
    width: "98%",
    height: "95%",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "90%",
    },
  },
  listOuterContainer: {
    display: "flex",
    width: "25%",
  },
  actionListOuterContainer: {
    position: "relative",
    height: "100%",
    width: "25%",
  },
  tabsContainer: {
    position: "relative",
    margin: "10px 10px 10px 0%",
    height: "100%",
    width: "75%",
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  actionsTabsContainer: {
    position: "relative",
    margin: "10px 10px 10px 0%",
    height: "100%",
    width: "75%",
    display: "flex",
    flexDirection: "column",
  },
  tabPanel: {
    marginTop: "15px",
    overflow: "auto",
    boxSizing: "border-box",
    height: "100%",
  },
  visitReport: {
    height: "70vh",
    overflowY: "scroll",
    overflowX: "clip",
    [theme.breakpoints.down("xs")]: {
      height: "60vh",
    },
  },
}));

export const DesktopViewStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
