import React, { forwardRef } from "react";
import ReportFilter from "./components/reportFilter";
import { ReportFilterPanelStyles } from "./styles/reportFilterPanelStyles";
import FilterConfigurationOptions from "./components/filterConfigurationOptions";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  GetCurrentFilterSet,
  GetVisibleFilters,
  RepotIsBusy,
  GetLocationFilterOptions,
  GetVisitDateFilterOptions,
  GetAllFilters,
  AllTaskCategories,
  GetUserCurrentBrands,
  AllUsedQuestionnaireTags,
  GetApplicableVisitTypesByRef,
  GetQuestionnaireFilterOptionsByVisitTypes,
  GetPeriodHierarchyOptions,
  AllTaskAssignees,
  // GetDishFilterOptions,
} from "./state/selectors";
import { RootState } from "../../state/reducers/RootReducer";
import { IQuestionnaireFilterOption } from "../../state/types/FilterOptions";
import clsx from "clsx";

interface IProps {
  pageRef: string;
  restrictToVisitTypes?: string[];
  appealsFeatureActive?: boolean;
  drawerOpen?: boolean;
  ref: any;
}

const ReportFilterPanel = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const classes = ReportFilterPanelStyles();
  const filterSet = useSelector(GetCurrentFilterSet);
  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, props.pageRef)
  );

  const allFilters = useSelector((state: RootState) =>
    GetAllFilters(state, props.pageRef)
  );
  const reportIsBusy = useSelector(RepotIsBusy);

  const currentBrands = useSelector(GetUserCurrentBrands);
  const locationOptions = useSelector((state: RootState) =>
    GetLocationFilterOptions(state, currentBrands)
  );
  const visitDateOptions = useSelector(GetVisitDateFilterOptions);
  const periodHierarchyOptions = useSelector((state: RootState) =>
    GetPeriodHierarchyOptions(state, currentBrands)
  );

  const usedQuestionnaireTags = useSelector(AllUsedQuestionnaireTags);

  const applicableVisitTypes = useSelector((state: RootState) =>
    GetApplicableVisitTypesByRef(state, props.pageRef)
  );

  const questionnaireOptions = useSelector((state: RootState) =>
    GetQuestionnaireFilterOptionsByVisitTypes(
      state,
      props.restrictToVisitTypes
        ? props.restrictToVisitTypes
        : applicableVisitTypes
    )
  );

  const filteredQuestionnaireOptions: IQuestionnaireFilterOption[] =
    questionnaireOptions.map((x) => {
      return {
        visitType: x.visitType,
        questionnaires: x.questionnaires.filter((q) =>
          q.applicableToBrands.some((b) => currentBrands.indexOf(b) > -1)
        ),
      };
    });

  const taskCategoryOptions = useSelector(AllTaskCategories);
  const taskAssignees = useSelector(AllTaskAssignees);
  return (
    <div ref={ref} style={{ display: "flex" }}>
      {filterSet && filters.length > 0 && (
        <Grid
          container
          alignItems="flex-start"
          style={{ marginLeft: "0.5%" }}
          spacing={2}
        >
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <div
              className={clsx(`hub-no-print ${classes.filterTasksContainer}`, {
                [classes.filterTasksContainerShift]: props.drawerOpen,
              })}
            >
              <FilterConfigurationOptions
                filters={filters}
                filterSet={filterSet}
                availableFilters={allFilters}
                questionnaireTags={usedQuestionnaireTags}
              />
            </div>
          </Grid>
          <Grid
            container
            item
            xs={9}
            sm={9}
            md={10}
            lg={10}
            alignItems="baseline"
          >
            {filters.map((filter, index) => {
              return (
                <div key={index} className={classes.filter}>
                  <ReportFilter
                    key={index}
                    hubFilter={filter}
                    reportIsLoading={reportIsBusy}
                    pageInternalRef={props.pageRef}
                    allCurrentFilters={filters}
                    locationOptions={locationOptions}
                    visitDateOptions={visitDateOptions}
                    periodHierarchyOptions={periodHierarchyOptions}
                    questionnaireOptions={filteredQuestionnaireOptions}
                    taskCentreCategoryOptions={taskCategoryOptions}
                    taskAssigneeOptions={taskAssignees}
                    appealsFeatureActive={props.appealsFeatureActive}
                    // dishFilterOptions={dishFilterOptions}
                  />
                </div>
              );
            })}
          </Grid>
        </Grid>
      )}
    </div>
  );
});

export default ReportFilterPanel;
