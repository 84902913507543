import { useSelector } from "react-redux";
import UserMenu from "./components/userMenu";
import { AccountSelectionProps } from "./components/accountSelectionDialog";
import { UserToolbarStyles } from "./styles/userToolbarStyles";
import {
  UserBrandsSelector,
  UserCanSwitchClientsSelector,
  UserClientsSelector,
  UserCurrentBrandsSelector,
  UserImpersonationPendingSelector,
  UserImpersonationSelector,
  UserNameSelector,
  UserNavigationOptionsSelector,
} from "./state/selectors";
import AnnounceKitWidget from "./components/AnnounceKit/AnnounceKitWidget";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Icon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PageTitle from "../pageTitle/pageTitle";
import { useLocation } from "react-router";

import { NavigationType } from "../../state/types/UserProfile";

type props = {
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  showFilters: boolean;
  drawerOpen: boolean;
  pageTitle: string;
};
const UserToolbar = (props: props) => {
  const classes = UserToolbarStyles();
  const location = useLocation();

  const userName = useSelector(UserNameSelector);
  const userNavOptions = useSelector(UserNavigationOptionsSelector);
  const userClients = useSelector(UserClientsSelector);
  const userBrands = useSelector(UserBrandsSelector);
  const currentBrands = useSelector(UserCurrentBrandsSelector);
  const userCanSwitchClients = useSelector(UserCanSwitchClientsSelector);
  const userImpersonation = useSelector(UserImpersonationSelector);
  const userImpersonationPending = useSelector(
    UserImpersonationPendingSelector
  );
  const [accountSelectionProps, setAccountSelectionProps] = useState<
    AccountSelectionProps | undefined
  >();

  useEffect(() => {
    const accountSelectionProps = {
      availableClients: userClients,
      userCanSwitchClients: userCanSwitchClients,
      userImpersonation: userImpersonation,
      impersonationPending: userImpersonationPending,
    };
    setAccountSelectionProps(accountSelectionProps);
  }, [
    userClients,
    userBrands,
    currentBrands,
    userCanSwitchClients,

    userImpersonation,
    userImpersonationPending,
  ]);
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );

  const dynamicBorder = location.pathname.includes("/inbox")
    ? `${classes.userMenuItem} ${classes.noBorder}`
    : `${classes.userMenuItem}`;

  return (
    <>
      <Typography>{`${props.pageTitle}`}</Typography>
      <div className={classes.flexRow}>
        <div className={`hub-no-print ${classes.userMenuGroup}`}>
          {isMobileView && (
            <Icon
              className={`fas fa-filter-list ${classes.mobileFilterButton}`}
              onClick={() => props.setShowFilters(!props.showFilters)}
            />
          )}
          {!location.pathname.includes("/inbox") && (
            <span className={`${classes.userMenuItem} ${classes.noBorder}`}>
              <PageTitle /> {/*TODO: Rename this */}
            </span>
          )}
          <span className={dynamicBorder}>
            <AnnounceKitWidget />
          </span>
          {accountSelectionProps && (
            <span className={`${classes.userMenuItem} ${classes.profileSpan}`}>
              <Tooltip
                title={userName}
                enterDelay={750}
                placement="bottom"
                PopperProps={{ disablePortal: true }}
                arrow={true}
              >
                <UserMenu
                  userName={userName}
                  userNavOptions={userNavOptions.filter(
                    (navOption) =>
                      navOption.navigationType === NavigationType.UserContext
                  )}
                  AccountSelectionProps={accountSelectionProps}
                />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default UserToolbar;
