import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { IKeyValuePair } from "../../../state/types/FilterSets";
import { visitSourcesAPI } from "../../../state/api/FilterOptions-API";
import { IVisitSourceOption } from "../../../state/types/FilterOptions";
import DisabledFilter from "./disabledFilter";
import ErrorFilter from "./errorFilter";
import { i18n } from "../../../localizations";

interface IProps {
  clientId: number;
  selectedVisitSources: IKeyValuePair[];
  applySelectionCallback: (selections: IKeyValuePair[]) => void;
}

const VisitSourcePicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVisitSources, setSelectedVisitSources] = useState(
    [] as IKeyValuePair[]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [availableVisitSources, setAvailableVisitSources] =
    useState<IVisitSourceOption[]>();

  const fetchVisitSourceData = () => {
    setErrorLoading(false);
    setIsLoading(true);
    if (!availableVisitSources && props.clientId) {
      visitSourcesAPI(props.clientId)
        .then((res) => {
          const visitSources = res.data.data?.visitSourceByClientId || [];

          if (res.data.errors && res.data.errors.length > 0) {
            setErrorLoading(true);
          }
          if (visitSources.length > 0) {
            setAvailableVisitSources(visitSources);
          }
        })
        .catch(() => {
          setErrorLoading(true);
        })
        .then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchVisitSourceData();
  }, [props.clientId]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedVisitSources.length === 0 ||
      selectedVisitSources.length === availableVisitSources?.length
    ) {
      selectionText = i18n.translate("VISIT_SOURCE_PICKER_All_Selected");
    } else if (selectedVisitSources.length === 1) {
      selectionText = selectedVisitSources[0].value;
    } else {
      selectionText = `${selectedVisitSources.length} ${i18n.translate(
        "VISIT_SOURCE_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    return selectedVisitSources.map((x) => x.value).join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    const selectedIds = availableVisitSources
      ?.map((x) => {
        return {
          key: x.id,
          value: x.description,
        } as IKeyValuePair;
      })
      .filter((x) => selectedOptions.indexOf(x.value) > -1);
    if (selectedIds) {
      props.applySelectionCallback(selectedIds);
    }
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedVisitSources(props.selectedVisitSources);
  }, [setSelectedVisitSources, props.selectedVisitSources]);

  return (
    <>
      {errorLoading && (
        <ErrorFilter
          label={i18n.translate("VISIT_SOURCE_PICKER_Visit_Source")}
          retry={fetchVisitSourceData}
        />
      )}
      {isLoading && !errorLoading && (
        <DisabledFilter
          label={i18n.translate("VISIT_SOURCE_PICKER_Visit_Source")}
        />
      )}
      {!isLoading && !errorLoading && availableVisitSources && (
        <FilterWrapper
          label={i18n.translate("VISIT_SOURCE_PICKER_Visit_Source")}
          selectionHint={getAppliedSelectionText()}
          isOpen={isOpen}
          toggleOpenState={handleToggleOpen}
          tooltip={buildTooltipContent()}
        >
          <GenericMultiSelect
            useSelectAllOption={false}
            selectAllOptionText={i18n.translate(
              "VISIT_SOURCE_PICKER_Select_All_Option"
            )}
            multiSelect={true}
            options={availableVisitSources?.map((x) => x.description)}
            selectedOptions={selectedVisitSources.map((x) => x.value)}
            applySelectionCallback={applySelection}
            cancelSelectionCallback={cancelSelection}
          />
        </FilterWrapper>
      )}
    </>
  );
};

export default VisitSourcePicker;
