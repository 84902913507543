import { useCallback, useState } from "react";
import { PageContainerStyles } from "./styles/pageContainerStyles";
import { InboxVisitTypes } from "../../utils/hgem";
import ReportFilterPanel from "../reportFilterPanel/reportFilterPanel";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { routeParameters } from "../../navigation/routes";
import { RootState } from "../../state/reducers/RootReducer";
import { GetReportInternalRefByPageRoute } from "../../views/state/selectors";
import { AppealsFeatureActive } from "../inboxViews/state/selectors";

interface IProps {
  children: JSX.Element;
  renderFilters: boolean;
  drawerOpen: boolean;
}

const PageContainer = (props: IProps) => {
  const classes = PageContainerStyles();

  const routeParams = useParams<routeParameters>();

  const reportName = routeParams.reportName
    ? routeParams.reportName.toLowerCase()
    : undefined;
  const pageInternalRef = useSelector((s: RootState) =>
    GetReportInternalRefByPageRoute(
      s,
      reportName ? reportName : "ReportNotFound"
    )
  );

  const inboxRef = location.pathname.includes("Conversations")
    ? "Conversations"
    : "Inbox";
  const pageRef = location.pathname.includes("/inbox")
    ? inboxRef
    : pageInternalRef;
  const isDashboard =
    location.pathname.includes("/mydashboard") ||
    location.pathname.includes("/dashboard");
  const appealsFeatureActive = useSelector(AppealsFeatureActive);
  const [height, setHeight] = useState(0);
  const filterRef = useCallback((node) => {
    if (node !== null) {
      const resizeObserver = new ResizeObserver(() => {
        setHeight(node.getBoundingClientRect().height);
      });
      resizeObserver.observe(node);
    }
  }, []);
  const adjustedHeight = height < 20 ? 0 : height - height / 5;
  return (
    <>
      {props.renderFilters && (
        <ReportFilterPanel
          ref={filterRef}
          drawerOpen={props.drawerOpen}
          pageRef={pageRef}
          restrictToVisitTypes={
            pageRef === inboxRef || isDashboard ? InboxVisitTypes : undefined
          }
          appealsFeatureActive={appealsFeatureActive}
        />
      )}
      <div
        style={{
          minHeight: 0,
        }}
        className={`${classes.spacer} hub-print-horizontal-margin `}
      >
        {props.children}
      </div>
    </>
  );
};

export default PageContainer;
