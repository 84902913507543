import { HubFilterType, IHubFilter } from "../../../state/types/FilterSets";
import {
  IDateRange,
  IGetVisitDetailsQuery,
} from "../../../state/types/VisitDetailsQuery";
import {
  convertNpsClassificationToScores,
  getFilterSelection,
  IFilterDates,
  IFilterScoreRange,
} from "../../../utils/hubFilterReader";

const BuildVisitDetailsListQuery = (
  visitListItemCount: number,
  hubFilters: IHubFilter[],
  defaultVisitTypes: string[],
  selectedBrands: string[],
  requestedVisitId: number | undefined,
  returnVisitsWithPhotos: boolean | undefined
): IGetVisitDetailsQuery => {
  const visitSources = getFilterSelection(
    HubFilterType.VisitSource,
    hubFilters
  ) as number[];
  const questionnaireTypes = getFilterSelection(
    HubFilterType.QuestionnaireType,
    hubFilters
  ) as string[];
  const filteredLocationIds = getFilterSelection(
    HubFilterType.Locations,
    hubFilters
  ) as number[];
  const filteredDates = getFilterSelection(
    HubFilterType.VisitDate,
    hubFilters
  ) as IFilterDates;
  const filteredTaskStatuses = getFilterSelection(
    HubFilterType.TaskCentreStatus,
    hubFilters
  ) as number[];
  const filteredAppealStatuses = getFilterSelection(
    HubFilterType.AppealStatus,
    hubFilters
  ) as number[];
  const filteredTaskPriorities = getFilterSelection(
    HubFilterType.TaskCentrePriority,
    hubFilters
  ) as number[];
  const filteredTaskCategories = getFilterSelection(
    HubFilterType.TaskCentreCategory,
    hubFilters
  ) as number[];
  const filteredFbCategories = getFilterSelection(
    HubFilterType.FeedbackCategory,
    hubFilters
  ) as string[];
  const filteredFbSubCategories = getFilterSelection(
    HubFilterType.FeedbackSubCategory,
    hubFilters
  ) as string[];
  const filteredDays = getFilterSelection(
    HubFilterType.DayOfWeek,
    hubFilters
  ) as string[];
  const filteredAssignees = getFilterSelection(
    HubFilterType.TaskAssignee,
    hubFilters
  ) as number[];
  const filteredContactStatus = getFilterSelection(
    HubFilterType.VisitContactStatus,
    hubFilters
  ) as string;

  const filteredVisitScores = getFilterSelection(
    HubFilterType.VisitScoreRange,
    hubFilters,
    null
  ) as IFilterScoreRange;
  const filteredNpsClassification = getFilterSelection(
    HubFilterType.NPS,
    hubFilters
  ) as string[];
  // const filteredDishNames = getFilterSelection(
  //   HubFilterType.DishFilters,
  //   hubFilters
  // ) as number[];
  let visitTypes = getFilterSelection(
    HubFilterType.VisitType,
    hubFilters
  ) as string[];

  if (!visitTypes || visitTypes.length === 0) {
    visitTypes = defaultVisitTypes;
  }

  let visitDates: IDateRange | undefined = undefined;
  let visitPeriods: string[] | undefined = undefined;
  let periodHierarchies: number[] = [];

  if (
    filteredDates.periodHierarchies &&
    filteredDates.periodHierarchies.length > 0
  ) {
    periodHierarchies = filteredDates.periodHierarchies;
  } else if (filteredDates.periods && filteredDates.periods.length > 0) {
    visitPeriods = filteredDates.periods;
  } else {
    visitDates = {
      start: filteredDates.startDate,
      end: filteredDates.endDate,
    } as IDateRange;
  }

  const nextPageIndex = getNextPageIndex(visitListItemCount);

  const batchValue = process.env.REACT_APP_VISIT_DETAILS_LIST_BATCH_SIZE;
  const batchSize = Number.parseInt(batchValue ? batchValue : "100");

  const visitsWithPhotos = returnVisitsWithPhotos || false;
  const query: IGetVisitDetailsQuery = {
    visitId: requestedVisitId,
    excludeFiltersWithVisitId: false,
    locationIds: filteredLocationIds,
    brands: selectedBrands,
    questionnaireTypes: questionnaireTypes,
    visitTypes: visitTypes,
    visitDates: visitDates,
    visitPeriods: visitPeriods,
    periodHierarchies: periodHierarchies,
    workflowStatuses: filteredTaskStatuses,
    appealWorkflowStatuses: filteredAppealStatuses,
    priorities: filteredTaskPriorities,
    categories: filteredTaskCategories,
    pagination: { itemsPerPage: batchSize, pageIndex: nextPageIndex },
    visitSources: visitSources,
    feedbackCategories: filteredFbCategories,
    feedbackSubCategories: filteredFbSubCategories,
    daysOfWeek: filteredDays,
    assignees: filteredAssignees,
    contactStatus: filteredContactStatus,
    visitScoreRange: filteredVisitScores,
    npsScores: convertNpsClassificationToScores(filteredNpsClassification),
    returnVisitsWithPhotos: visitsWithPhotos,
    // dishes: filteredDishNames,
  };
  return query;
};

const getNextPageIndex = (visitListItemCount: number): number => {
  let numberOfPagesLoaded = 0;

  const batchValue = process.env.REACT_APP_VISIT_DETAILS_LIST_BATCH_SIZE;
  const batchSize = Number.parseInt(batchValue ? batchValue : "100");

  if (visitListItemCount > 0) {
    numberOfPagesLoaded = Math.floor(visitListItemCount / batchSize);
  }

  return numberOfPagesLoaded;
};

export default BuildVisitDetailsListQuery;
