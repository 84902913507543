import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  userMenuGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    color: hgemColours.DarkGrey,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "3%",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start",
    marginTop: "auto",
    marginBottom: "auto",
  },
  userMenuItem: {
    borderLeft: "1px solid #c9c9c9",
  },
  mobileFilterButton: {
    borderRight: "1px solid #c9c9c9",
    paddingRight: "7%",
    cursor: "pointer",
  },
  noBorder: {
    border: "none",
  },
  profileSpan: {
    paddingLeft: "1%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5%",
    },
  },
  filterControl: {
    marginLeft: "1%",
    width: "100%",
    color: hgemColours.DarkGrey,
  },
}));

export const UserToolbarStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
