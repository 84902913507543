import { useRef, useState, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../state/reducers/RootReducer";
import { GetReportInternalRefByPageRoute } from "./state/selectors";
import ReportViewer from "../components/reportViewer/reportViewer";
import { routeParameters, routes } from "../navigation/routes";
import { useMediaQuery, useTheme } from "@material-ui/core";

const Reports = (props: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const routeParams = useParams<routeParameters>();
  const reportName = routeParams.reportName.toLowerCase();
  const [containerWidth, setContainerWidth] = useState(0);

  const pageInternalRef = useSelector((s: RootState) =>
    GetReportInternalRefByPageRoute(
      s,
      reportName ? reportName : "ReportNotFound"
    )
  );

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }

    if (!pageInternalRef) {
      history.push(routes.notFound);
    }
  }, [history, pageInternalRef]);
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );
  return (
    <div
      ref={containerRef}
      style={{ width: "100%", display: "flex", height: "100%" }}
    >
      <ReportViewer pageRef={pageInternalRef} containerWidth={containerWidth} />
    </div>
  );
};

export default Reports;
