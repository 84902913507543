import { Badge, Icon, ListItem, Typography, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { NavigationOptionStyles } from "../styles/navigationOptionStyles";
import { INavigationOption } from "../../../state/types/UserProfile";
import { i18n } from "../../../localizations";
import { useSelector } from "react-redux";
import { UnreadConversationsCount } from "../../../state/selectors/Conversations-Selectors";
import { hgemColours } from "../../../themes/defaultTheme";
import ReportPageNavigationList from "./reportPageNavigationList";

type props = {
  navOption: INavigationOption;
  currentParentNav: INavigationOption | undefined;
  currentChildNav: INavigationOption | undefined;
  handleNavigationChange: (navOption: INavigationOption) => void;
  isMobile: boolean;
  setDrawerOpen?: (boolean: boolean) => void;
  isChild: boolean;
  setReportPageName: (pageName: string) => void;
};
const SingleNavOption = (props: props) => {
  const {
    navOption,
    currentParentNav,
    handleNavigationChange,
    currentChildNav,
    isMobile,
    setDrawerOpen,
    isChild,
    setReportPageName,
  } = props;
  const classes = NavigationOptionStyles();

  const unreadConversationsCount = useSelector(UnreadConversationsCount);
  const navigate = useHistory();
  const renderBadge =
    navOption.name !== "Conversations" || unreadConversationsCount === 0;

  const isSelected =
    navOption.name === currentParentNav?.name ||
    navOption.name === currentChildNav?.name;

  const navClass = isChild ? classes.subNavOption : classes.navOption;

  const selectNavOption = (navOption: INavigationOption) => {
    handleNavigationChange(navOption);
    if (isMobile && setDrawerOpen) {
      setDrawerOpen(false);
    }
  };

  const linkClass = isSelected
    ? `${classes.navigationLink} ${classes.selectedNavigationLink}`
    : classes.navigationLink;

  const textVariant = navOption.parent ? "body1" : "subtitle1";
  return (
    <>
      <ListItem
        className={navClass}
        classes={{ selected: classes.selected }}
        selected={isSelected}
        onClick={(event) => {
          event.stopPropagation();
          selectNavOption(navOption);
          navigate.push(navOption.path);
        }}
      >
        <div className={classes.navOptionIconTitleContainer}>
          {navOption.icon && (
            <Icon className={`${navOption.icon} ${classes.navOptionIcon}`} />
          )}
          {!renderBadge && (
            <Badge
              badgeContent={unreadConversationsCount}
              invisible={renderBadge}
              overlap={"circular"}
              color={"primary"}
              classes={{ colorPrimary: hgemColours.Orange }}
            >
              <Link
                underline="none"
                className={linkClass}
                href={navOption.path}
                onClick={(event) => event.preventDefault()}
              >
                <Typography variant={textVariant}>
                  {navOption.navigationName[i18n.locale as string] ||
                    navOption.navigationName["en-GB"]}
                </Typography>
              </Link>
            </Badge>
          )}
          {renderBadge && (
            <>
              <Link
                underline="none"
                className={linkClass}
                href={navOption.path}
                onClick={(event) => event.preventDefault()}
              >
                <Typography
                  variant={textVariant}
                  className={classes.navOptionTitle}
                >
                  {navOption.navigationName[i18n.locale as string] ||
                    navOption.navigationName["en-GB"]}
                </Typography>
              </Link>
            </>
          )}
        </div>
      </ListItem>
      {navOption.path.includes("/reports/") &&
        props.currentParentNav &&
        props.setDrawerOpen && (
          <div style={{ width: "88%", paddingLeft: "10%" }}>
            <ReportPageNavigationList
              navOption={navOption}
              childNavigationOption={props.currentParentNav}
              currentChildNav={navOption}
              isMobile={isMobile}
              setDrawerOpen={setDrawerOpen}
              setReportPageName={setReportPageName}
            />
          </div>
        )}
    </>
  );
};
export default SingleNavOption;
