import { useMemo } from "react";
import { HGEMVisitType, InboxVisitTypes } from "./hgem";
import { useSelector } from "react-redux";
import { NavigationOptionsSelector } from "../components/navigationPanel/state/selectors";

export const useAllowedVisitTypes = () => {
  const userNavigationOptions = useSelector(NavigationOptionsSelector);
  const allowedVisitTypes = useMemo(() => {
    return InboxVisitTypes.filter((visitType) => {
      if (visitType === HGEMVisitType.ProjectAssessment) {
        const hasPermissionToViewPA = !!userNavigationOptions.find(
          (navPage) => navPage.name === "ProjectAuditOverview"
        );
        return hasPermissionToViewPA;
      }
      if (visitType === HGEMVisitType.EmployeeSurvey) {
        const hasPermissionToViewES = !!userNavigationOptions.find(
          (navPage) => navPage.name === "eNPSOverview"
        );
        return hasPermissionToViewES;
      }

      return true;
    });
  }, [userNavigationOptions]);

  return { allowedVisitTypes };
};
