import { Theme } from "@material-ui/core/styles";
import { hgemColours } from "../../../themes/defaultTheme";

export const ReportRenderStyles = (theme: Theme) => ({
  container: {
    backgroundColor: hgemColours.GreyReportBackground,
    minHeight: "400px",
    maxHeight: "100%",
    margin: "10px 0px 10px 10px",
    height: "auto",

    "& iframe": {
      border: "none",
      backgroundColor: theme.palette.background.paper,
    },
  },
  scrollContainer: {
    display: "flex",
    maxHeight: "100%",
    width: "100%",
  },
  outer: {
    display: "flex",
    maxHeight: "100%",
    width: "100%",
  },
});
