import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  filter: {
    display: "flex",
    float: "left",
    marginLeft: "0.5%",
    marginBottom: "0.5%",
  },
  filterTasksContainer: {
    "& button": {
      padding: "3px 10px",
    },
    filterTasksContainerShift: {},
  },
}));

export const ReportFilterPanelStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
