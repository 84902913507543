import axios, { AxiosResponse } from "axios";
import { IReviewContent, IReviewResponseTemplate } from "../types/VisitReport";

export const downloadVisitReportApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<Blob>> => {
  return axios.get<Blob>(
    `${process.env.REACT_APP_VISIT_DETAILS_API_BASEURL}VisitReport/${clientId}/${visitId}`,
    { responseType: "blob" }
  );
};

export const fetchVisitReportHtmlApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<string>> => {
  return axios.get<string>(
    `${process.env.REACT_APP_VISIT_DETAILS_API_BASEURL}VisitReport/${clientId}/${visitId}/html`
  );
};

export const fetchVisitReportReviewContentApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<IReviewContent>> => {
  return axios.get<IReviewContent>(
    `${process.env.REACT_APP_HUB_API_BASEURL}VisitReport/Client/${clientId}/GetReview/${visitId}`
  );
};

export const fetchReviewResponseTemplatesApi = (
  clientId: number,
  brandId: number | undefined | null
): Promise<AxiosResponse<IReviewResponseTemplate[]>> => {
  const NO_BRAND_ID_REPLACEMENT = -1; // The API struggles to accept null or undefined for this param. But it accepts -1 and will return the default set of templates rather than brand specific ones.
  const brand = brandId || NO_BRAND_ID_REPLACEMENT;
  return axios.get<IReviewResponseTemplate[]>(
    `${process.env.REACT_APP_HUB_API_BASEURL}VisitReport/Client/${clientId}/Brand/${brand}/GetReviewResponseTemplates`
  );
};

export const fetchEmailResponseTemplatesApi = (
  clientId: number,
  brandId: number | undefined | null = -1
): Promise<AxiosResponse<IReviewResponseTemplate[]>> => {
  return axios.get<IReviewResponseTemplate[]>(
    `${
      process.env.REACT_APP_HUB_API_BASEURL
    }VisitReport/Client/${clientId}/Brand/${
      brandId ?? -1
    }/GetEmailResponseTemplates`
  );
};
