import { useDispatch, useSelector } from "react-redux";
import { AllManagers, AllCategories, CaseListPending } from "./state/selectors";
import EditCaseItem from "./components/editCaseItem";
import QuickTaskCreator from "./components/quickTaskCreator";
import {
  ICaseDetails,
  IMinimalCaseEventAttachment,
} from "../../state/types/TaskCentreCases";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import TitleField from "./Inputs/titleField";
import TasksButtonGroup from "./components/tasksButtonGroup";
import { EditCaseItemStyles } from "./styles/editCaseItemStyles";
import InboxComments from "../InboxComments/inboxComments";
import {
  AttachmentFileSelector,
  AttachmentsList,
} from "../inboxViews/components/attachments";
import {
  AddCommentCommand,
  AttachmentsAddedCommand,
} from "../../state/types/TaskCentreCommands";
import {
  executeCaseCommand,
  fetchTaskCentreCategories,
} from "../../state/actions/TaskCentre-CaseList-Actions";
import { useClientId } from "../../state/hooks/clients/useClientId";
import { useIsAdmin } from "../../state/hooks/clients/useIsAdmin";
import { useUserId } from "../../state/hooks/clients/useUserId";
import {
  deleteTaskAttachmentApi,
  downloadTaskAttachmentApi,
} from "../../state/api/TaskCentre-API";
import { RootState } from "../../state/reducers/RootReducer";
import CommentField from "../InboxComments/components/commentField";
import { GetActiveNotificationById } from "../inboxReport/state/selectors";
import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { i18n } from "../../localizations";
import { TasksStyles } from "./styles/tasksStyles";

interface IProps {
  hideDeleteTask?: boolean;
  case: ICaseDetails | undefined;
  selectedVisitId?: number | undefined;
  isMobileView: boolean;
}

const TaskCentreEditor = (props: IProps) => {
  const casePending = useSelector(CaseListPending);
  const managers = useSelector(AllManagers);
  const categories = useSelector(AllCategories);
  const clientId = useClientId();
  const isAdmin = useIsAdmin();
  const userId = useUserId();

  const classes = EditCaseItemStyles();
  const taskClasses = TasksStyles();
  const dispatch = useDispatch();
  const fieldNotification = useSelector((state: RootState) =>
    GetActiveNotificationById(
      state,
      `${props.case?.id}|${AddCommentCommand.type}`
    )
  );
  const ref = useRef<null | HTMLDivElement>(null);
  const scroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchTaskCentreCategories());
    }
  }, []);
  const attachments: (IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  })[] =
    props.case?.events
      .flatMap(
        (event) =>
          event.caseEventAttachments?.map((attachment) => {
            return {
              ...attachment,
              authorId: event.authorId,
              authorName: event.authorName,
            };
          }) ?? []
      )
      .sort((a, b) => b.id - a.id) ?? [];

  const deleteTaskAttachment = async (
    caseEventAttachmentId: number
  ): Promise<void> => {
    if (props.case && caseEventAttachmentId) {
      deleteTaskAttachmentApi(
        clientId,
        props.case.vId,
        props.case.id,
        isAdmin ? undefined : userId,
        caseEventAttachmentId
      );
    }
  };

  const downloadAttachment = async (
    caseEventAttachment: IMinimalCaseEventAttachment,
    currentAppeal: ICaseDetails
  ) => {
    const response = await downloadTaskAttachmentApi(
      clientId,
      currentAppeal.vId,
      currentAppeal.id,
      caseEventAttachment.id,
      caseEventAttachment.filepath
    );

    return response.data;
  };

  const uploadFile = async (file: File, caseId?: number) => {
    if (caseId) {
      const attachmentCommand = new AttachmentsAddedCommand(caseId, [file]);
      dispatch(executeCaseCommand(caseId, [attachmentCommand]));
      setIsOpen(true);
    }
  };

  const title = (caseDetails: ICaseDetails) => {
    return (
      <div className={classes.titleContainer}>
        <div className={classes.titleField}>
          <TitleField title={caseDetails.title} caseId={caseDetails.id} />
        </div>
        <Tooltip
          enterDelay={150}
          placement="bottom"
          PopperProps={{ disablePortal: true }}
          arrow={true}
          title={i18n.translate("TASK_CENTRE_TASK_ID")}
        >
          <div className={classes.titlePrefix}>{caseDetails.id}</div>
        </Tooltip>
        <TasksButtonGroup
          caseId={caseDetails.id}
          caseTitle={caseDetails.title}
          visitId={caseDetails.vId}
          allowCreateNew
          allowDelete
          onFileSelected={(file) => uploadFile(file, caseDetails.id)}
          scroll={scroll}
          isMobileView={props.isMobileView}
        />
      </div>
    );
  };
  return (
    <div className={classes.container}>
      {props.case && !casePending && (
        <>
          {title(props.case)}

          <EditCaseItem
            caseDetails={props.case}
            showDelete={!props.hideDeleteTask}
            showAddNewTask={true}
            managers={managers}
            categories={categories}
          />
          <InboxComments
            visitId={props.case?.vId}
            case={props.case}
            showTaskSelector={false}
            hideAttachments={true}
            hideInput={true}
          />

          <CommentField
            caseDetails={props.case}
            fieldNotification={fieldNotification}
            isNewCase={false}
            hideAttachments={true}
          />

          <div
            style={{ marginTop: "2%", marginBottom: "5%", overflow: "visible" }}
            ref={ref}
          >
            <Accordion expanded={isOpen} onClick={() => setIsOpen(!isOpen)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ content: classes.accordionSummaryAlignment }}
              >
                {i18n.translate("ATTACHMENTS")}
                <span
                  aria-label="upload-attachment"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  style={{
                    marginLeft: "5%",
                  }}
                >
                  <AttachmentFileSelector
                    onFileSelected={(file: File) =>
                      uploadFile(file, props.case?.id)
                    }
                    className={taskClasses.tasksIconButton}
                    iconButtonOnly={true}
                  />
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <AttachmentsList
                  attachments={attachments}
                  downloadAttachment={
                    props.case
                      ? (attachment) =>
                          downloadAttachment(
                            attachment,
                            props.case as ICaseDetails
                          )
                      : undefined
                  }
                  authorDeleteAttachment={(authorId) =>
                    isAdmin || authorId === userId
                      ? deleteTaskAttachment
                      : undefined
                  }
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}

      {!props.case && props.selectedVisitId && (
        <QuickTaskCreator visitId={props.selectedVisitId} />
      )}
    </div>
  );
};

export default TaskCentreEditor;
