export enum HGEMVisitType {
  MysteryVisit = "MV",
  OnlineFeedback = "OF",
  RealCustomer = "RC",
  CommentCard = "CC",
  Review = "RV",
  EmployeeSurvey = "ES",
  TelephoneAssessment = "TA",
  ProjectAssessment = "PA",
  DeliveryAssessment = "DA",
  DeliveryReview = "DR",
  ProductSurvey = "PS",
  ExternalFeedback = "EF",
  TrackAndTrace = "TT",
  Conversation = "CV",
}

export const InboxVisitTypes: HGEMVisitType[] = [
  HGEMVisitType.MysteryVisit,
  HGEMVisitType.DeliveryAssessment,
  HGEMVisitType.RealCustomer,
  HGEMVisitType.TelephoneAssessment,
  HGEMVisitType.OnlineFeedback,
  HGEMVisitType.CommentCard,
  HGEMVisitType.Review,
  HGEMVisitType.DeliveryReview,
  HGEMVisitType.ExternalFeedback,
  HGEMVisitType.Conversation,
  HGEMVisitType.ProjectAssessment,
  HGEMVisitType.EmployeeSurvey,
];
