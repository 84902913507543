import React, { ReactNode, useRef } from "react";
import {
  useTheme,
  makeStyles,
  Theme,
  Button,
  Popover,
  Tooltip,
} from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";
import { i18n } from "../../../localizations";

interface IProps {
  label: string;
  selectionHint?: string;
  tooltip?: string;
  isOpen: boolean;
  toggleOpenState: () => void;
  isDisabled?: boolean;
  children: ReactNode;
  highlight?: boolean;
  error?: boolean;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  filterWrapper: {
    backgroundColor: hgemColours.White,
    borderRadius: "5px",
    border: `1px solid ${hgemColours.LighterGrey}`,
    color: hgemColours.DarkGrey,
    "& button": {
      padding: "1px 8px",
    },
  },
  activeFilter: {
    backgroundColor: hgemColours.LimeGreen,
  },
  selectionHint: {
    fontWeight: 300,
    marginLeft: "10px",
    paddingLeft: "10px",
  },
  errorMessage: {
    fontWeight: 300,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: "10px",
    paddingLeft: "10px",
    color: "red",
  },
}));

const FilterWrapper = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const buttonRef = useRef(null);

  const handleClickAway = () => {
    props.toggleOpenState();
  };
  return (
    <div className={`${classes.filterWrapper} `}>
      <Tooltip title={props.tooltip ? props.tooltip : ""} arrow>
        <Button
          style={{ color: "inherit" }}
          ref={buttonRef}
          onClick={props.toggleOpenState}
          disabled={props.isDisabled}
        >
          {props.label}
          {props.selectionHint && (
            <div className={classes.selectionHint}>{props.selectionHint}</div>
          )}
          {props.error && (
            <div className={classes.errorMessage}>
              {i18n.translate("FILTERS_ERROR_LOADING")}
            </div>
          )}
        </Button>
      </Tooltip>
      <Popover
        open={props.isOpen}
        anchorEl={buttonRef.current}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.children}
      </Popover>
    </div>
  );
};

export default FilterWrapper;
