import React from "react";
import { IVisitDetail } from "../../../state/types/VisitDetails";
import { useSelector } from "react-redux";
import { VisitDetailsCount } from "../../../state/selectors/VisitDetails-Selectors";
import { InboxListStyles } from "../styles/inboxListStyles";
import { i18n } from "../../../localizations";
import InfiniteScrollWindow from "../../_common/scrollWindow/InfiniteScrollWindow";

interface IProps {
  skeleton: React.ReactElement;
  isLoading: boolean;
  visits: IVisitDetail[];
  visitView: (visitDetails: IVisitDetail, index?: number) => React.ReactElement;
  emptyVisits?: React.ReactElement;
  after?: React.ReactElement;
  scrollWindowStyle?: React.CSSProperties;
  fetchMoreVisits?: () => void;
  moreVisitsAvailable?: boolean;
}

const VisitList = (props: IProps) => {
  const visitCount = useSelector(VisitDetailsCount);
  const classes = InboxListStyles();

  return (
    <>
      <div className={classes.visitCountText}>
        {visitCount !== undefined && visitCount !== null && (<>
            {visitCount}{" "}
            {i18n.translate("VISIT_DETAILS_LIST_RESULTS_MATCHING_FILTERS")}
        </>)}
      </div>
      <InfiniteScrollWindow
        shouldFetchMore={props.moreVisitsAvailable}
        scrollCallback={props.fetchMoreVisits}
        isLoading={props.isLoading}
      >
        <>
          {props.isLoading && props.visits.length === 0 && props.skeleton}
          {!props.isLoading && props.visits.length === 0 && props.emptyVisits}
          {props.visits.map(props.visitView)}
        </>
      </InfiniteScrollWindow>
    </>
  );
};

export default VisitList;
